@import url("fonts/Monserat/Montserrat.css");

body {
  margin: 0;
  font: 15px 'Montserrat',sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #faf5ea;
}
