.footer {
  margin: 0;
  height : fit-content;
  padding-top: 20px;
  padding-right: 5%;
  padding-left: 5%;
  text-align: left;
  font-weight: 500;
  background-color: #274001;
  opacity: 0.9;
  color: bisque;
  text-shadow: rgba(0, 0, 0, 0.3) 0px 0.5px 0.5px;
}
  
.footer .desc {
  font-size: 13.5px;
  width: 90%;
  margin: unset;
}

.footer .address
{
  font-size: 14px;
  font-weight: 300;
  padding: 0;
}


.footer .desc-italic {
  font-style: italic;
  font-size: 13.5px;
  width: 90%;
  margin: initial;
}

.footer .contact button {
  border: unset ;
  background-color: unset;
  color: unset ;
  margin: 0;
  margin-left: 5%;
  padding: 0;
  font-weight: 400;
  font-size: 15px;
}

.footer h3{
  font-size: 14px;
}

.ft-body
{
    margin-left: 5%;
}

.footer h4 {
  color: bisque;
}

.footer p
{
  font-size:"11px";
  padding: 20px 0 20px 0;
  margin: 0;
  margin-top: 10px;
}

.footer a:link
{
  font-weight: 400;
}

.footer .contact .selfLink
{
  font-weight: 700;
}

.footer .seperator
{
  border-color: #faf5ea;
}