@media only screen and (min-width : 1024px) {
    .Navbar .menu {display: none;}

    .Navbar .menuButtons {
        position: relative !important;
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: nowrap !important;
        left: 0 !important;
        width: 100% !important;
        max-height: 150px !important;
    }

    .Navbar button {
        padding-top: 15px;
        padding-bottom: 15px;
        align-self: auto;
        display: initial !important;
        font-size: 16px;
    }

    .Navbar button.close {
        padding-top: 16px;
        padding-bottom: 16px;
        animation: none !important;
        height: auto !important;
        font-size: 16px !important;
    }

    .logo {
        height: auto !important;
        margin-left: 0;
        text-align: left;
    }

    .Navbar img{
        position: relative !important;
        width: auto !important;
    }

    .TitleContainer h3 {
        font-size: 250%;
        word-spacing: 0.2cm;
    }

    .container ul {
        margin-left: 5%;
    }
}

@media only screen and (min-width: 1300px) {
    .App {
        margin-left: 10%;
        max-width: 80%;
    }

    .Navbar {
        max-width: 80%;
    }
}

@media only screen and (min-width: 1700px) {
    .App {
        margin-left: 20%;
        max-width: 60%;
    }

    .Navbar {
        max-width: 60%;
    }
}
