.contact
{
    font-weight:lighter;
    font-size: 13.5px;
    letter-spacing: 0.2px;
    text-align: left;
}

.contact button {
    font-size: 13.5px;
    font-family: Arial, Helvetica, sans-serif;;
    margin-left: 7.5%;
    padding: 12.5px 40px 12.5px 40px;
    background-color: #faf4e8;
    border: 2px solid rgba(139, 190, 62, 1);
    border-radius: 12.5px;
    color: rgba(139, 190, 62, 1);
    transition: border-color 0.4s ease 0s, background-color 0.4s ease 0s;
}

.contact button:hover {
    color: white;
    background-color: rgba(225, 230, 202, 1);
    cursor: pointer;
}

.contact p, .contact h2
{
    margin-left: 5% !important;
}

.contact h2
{
    font-size: 31px;
    letter-spacing: normal;
    font-weight: 800;
}


.contact a {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.contact .selfLink
{
    text-decoration: none;
    font-weight:bold;
}

.contact a:link {
    color: #faf5ea;
}

.contact a:visited 
{
    text-decoration: none;
    color: #faf5ea;
}