.App {
  text-align: center;
  scroll-behavior: smooth;  
}

.spacer {
  height: 200px;
}

h3 {
  margin-left: 5%;
}

h4
{
  margin: 0% 5% 5% 5%;
  padding-top: 5%;
  font-weight:bold;
  font-size: 31px;
  letter-spacing: 5px;
}

