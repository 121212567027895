/* ABOUT */
.title {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.title h3
{
    font-size: 36px;
}

.aboutpage .title img {
    align-self: center;
    width: 265px;
    height: 265px;
    object-fit: cover; 
    border: 10px solid white;
}

.career, .qualifikation
{
    width: 90%;
    text-align: left;
    margin-left: 5%;
}

.career h5, .qualifikation h5
{
    font-size: larger;
}

.career ul, .qualifikation ul{
    list-style-position:outside;
    left: 20px;
    list-style-type: disc;
}

.career li, .qualifikation li{
    padding: 10px 0 0 0;
}
