.homepage {
    text-shadow: #fff 0px 1px 1px;
    background-color:#faf4e8;
}

.homepage img {
    object-fit: cover; 
    width: 100%;
    height: 250px;
    margin-bottom: -5px;
}

.homepage .text-desc {
    font-size: 15px !important;
    font-weight: bold;
    letter-spacing: 0.2px;
}

.homepage ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.homepage li{
    padding: 10px 0 0 0;
}

.homepage .quote {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    font-style: italic;
    color: #8bbe3e;
}


.homepage .contact h4
{
    font-size: 32px;
    letter-spacing: 6px;
}

.homepage .left h4
{
    text-align: left;
    padding: 0;
}

.homepage .left {
    margin-top: 20px;
}