.Navbar {
    font-size: 0;
    width: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    background-color: #faf5ea;
    z-index: 2;
    opacity: 0;
    animation: fade-with-scroll 1s;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -2s);
}

@keyframes fade-with-scroll {
    from { 
        opacity: 1;
        display: initial;
    }
    to {
        opacity: 0;
    }
}

.Navbar .menuButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    letter-spacing: 2px;
    position: relative;
    top: 0; 
    width: 100%;
    height: auto;
}

.Navbar .logo {
    position: relative;
    width: 100%;
    height: 0px;
}

.Navbar img {
    object-fit: scale-down;
    position: fixed;
    left: 0;
    max-height: 150px;
    width: 100%;
}


.Navbar button {
    flex-grow: 0;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    padding: 0;
    height: auto;
    background-color: #e1e6ca;
    font-size: 0;
    width: 100%;
    border: unset;
    position: relative;
}

@keyframes open-menu {
    from {
        height: 0;
        font-size: 0;
    }
    to {
        height: 30px;
        font-size: 18px;
        flex-grow: 1;
    }
}

@keyframes collapse-menu {
    from {
        height: 30px;
        font-size: 18px;
        flex-grow: 1;
    }
    to {
        height: 0;
        font-size: 0;
    }
}

.Navbar button.open {
    height: 30px;
    flex-grow: 1;
    font-size: 18px;
    animation: open-menu 0.5s;
}

.Navbar button.close {
    animation: collapse-menu 0.5s;
    height: 0;
    font-size: 0;
}

.Navbar button:active {
    color: bisque;
    background-color: rgb(1, 184, 1);
}

.Navbar button:hover {
    color: bisque;
    background-color: rgb(8, 151, 39);
}

.Navbar button.TabActive
{
    color: bisque;
    background-color: darkgreen;
}

.Navbar div.menu {
    position: relative;
    width: fit-content;
    width: 75px;
    margin-top: 150px;
    height: 50px;
    margin-bottom: 5px;
    margin-left: 5px;
}

.Navbar div.menu.open {
    margin-top: 10px ;
    animation: slide-menu-open 0.5s ease;
}

@keyframes slide-menu-open {
    from {
        margin-top: 150px;
    }
    to {
        margin-top: 10px;
    }
}

.Navbar div.menu.close {
    margin-top: 150px;
    animation: slide-menu-close 0.5s ease;
}

@keyframes slide-menu-close {
    from {
        margin-top: 10px;
    }
    to {
        margin-top: 150px;
    }
}

label.hamburger { 
    display: block;
    position: relative; 
    background: #faf5ea;
    margin-left: auto; margin-right: auto;
    border-radius: 4px;
}

.line { 
    display: block; 
    position: absolute; 
    left: 10px;
    height: 4px; width: 55px; 
    background: black; border-radius: 1px;
    transition: 0.5s; 
    transform-origin: center; 
 }

.hamburger .line:nth-child(1) { top: 12px; }
.hamburger .line:nth-child(2) { top: 24px; }
.hamburger .line:nth-child(3) { top: 36px; }


.menu.open .hamburger .line:nth-child(1){
    transform: translateY(12px) rotate(-45deg);
 }
 
.menu.open .hamburger .line:nth-child(2){
    opacity:0;
}
 
.menu.open .hamburger .line:nth-child(3){
    transform: translateY(-12px) rotate(45deg);
}
