@media only screen and (min-width : 640px) {
    .container {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
    }

    .container .head {
        width: 100%;
    }

    .container .column-50 { width: 50%; }

    .container img {
        min-height: 500px;
    }
    .container .column-50 img {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    .aboutpage img {
        object-fit: cover !important;
        height: 265px !important;
        min-height: 0 !important;
        width: 265px !important;
    }

    .aboutpage .zertifikate {
        margin-left: 50px;
    }

    .aboutpage .zert {
        padding: 2% !important;
        object-fit: fill;
        min-height: 69px !important;
        width: auto !important;
    }
   
    .logo {
        width: auto;
        align-self: flex-start;
        align-content: flex-start;
        margin-left: 20px;
    }

    .TitleContainer {
        min-height: 500px;
    }

    .TitleContainer img {
        width: 100%;
        position: absolute;
        object-fit: cover;
        left:0;
        margin-top: 0px !important;
    }
      
    @keyframes fade-with-scroll {
        from { 
            font-size: 32px;
            height: 20vh;
            opacity: 1;
            display: initial;
        }
        to {
            font-size: 32px;
            height: 20vh;
            opacity: 1;
        }
    }
}
