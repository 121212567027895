.TitleContainer
{
    position: relative;
    text-align: center;
    width : 100%;
    color : #FAF4E8;
}

.TitleContainer h3 {
    font-size: 24px;
    text-shadow: rgba(0, 0, 0, 0.5) 3px 4px 5px;
    line-height: 1.4em;
    margin: 0 !important;
    padding: 0;
    font-weight: bolder;
    letter-spacing: 3px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.container {
    background-color: #e1e6ca;
    text-shadow: rgb(204, 209, 182) 0px 1px 1px;
    text-align: left;
}

.container img {
    object-fit: cover; 
    min-height: 250px;
    width: 100%;
}

.container .zertifikate {
    margin-left: 50px;
}

.container img.zert {
    padding: 2%;
    width: auto !important;
    max-height: 120px;
    min-height: 0;
}

.container ul
{
    font-weight: 400;
    font-size: 100%;
    width: 80%;
    margin-bottom: 0;
    padding-bottom: 5%;
}

.container li
{
    padding-bottom: 5%;
}

.container span p {
    margin: 5%;
}

.container a:link {
    color: #274001 !important;
}

.container a:visited 
{
    color: #274001 !important;
}

.container h4 {
    text-align: left;
    color: #274001;
    font-size: 20px;
    letter-spacing: 2px;
    word-wrap: break-word;
    font-weight: 800;
    margin: 0;
    margin-left: 5%;
    padding: 0;
    padding-top: 5%;
    padding-bottom: 10px;
}

.container h3 {
    width: 80%;
    margin-left: 10%;
    font-weight: 700;
    text-align: center;
    font-family: Montserrat;
    padding-top: 5%;
    letter-spacing: 3px;
}

.seperator {
    left : 20px;
    width: 40px;
    margin: 5%;
    border: 1px solid rgba(39, 64, 1, 1);
}

.quote {
    color: #8bbe3e;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
}
